import Layout from "../components/Layout";
import DynamicComponent from "../components/DynamicComponent";
import getStaticStoryblokProps from "../lib/getStaticStoryblokProps";

export default function Home({ header, story, preview }) {
  return (<Layout header={header} story={story} preview={preview} isHomePage>
    <DynamicComponent shared={null}/>
  </Layout>)
}

export async function getStaticProps({ preview = false }) {
  return getStaticStoryblokProps({ params: null, preview });
}